import { BrowserRouter} from "react-router-dom";
import { AppRoutes } from "./routes/routes";
import LoaderBackdrop from './components/LoaderBackdrop';
import EntryPage from './pages/EntryPage';
import { SchoolsContext } from "./contexts/SchoolsContext";
import useSchool from "./hooks/useSchool";
import { useEffect } from "react";
import Cookies from 'js-cookie';

export default function App() {
    const {schoolsData, currentSchoolSlug, setCurrentSchoolSlug} = useSchool();

    useEffect(() => {
        window.addEventListener('OneTrustGroupsUpdated', (e) => {
            const activeCookieGroups = e.detail;

            // '2' is used for google cookies
            if (!activeCookieGroups.includes('2')) {
                // delete Google cookies
                const googleCookieNames = Object.keys(Cookies.get()).filter(name => name.startsWith('_ga'));
                googleCookieNames.forEach(cookieName => {
                    Cookies.remove(cookieName, {
                        path: '/',
                        domain: 'alumnilife.ca',
                    });
                    Cookies.remove(cookieName, {
                        path: '/',
                        domain: '.alumnilife.ca',
                    });
                    Cookies.remove(cookieName, {
                        path: '/',
                        domain: 'localhost',
                    });
                });
            }
        });
    }, []);

    return (
        <SchoolsContext.Provider value={
            {
                schoolsData,
                currentSchoolSlug,
                setCurrentSchoolSlug
            }
        }>
            <BrowserRouter children={AppRoutes}>
            </BrowserRouter>
        </SchoolsContext.Provider>
    );
}