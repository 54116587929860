import { useEffect, useLayoutEffect } from 'react';
import { useLocation, pathna } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export default function LanguagePath({lang}) {
    const { i18n, t } = useTranslation();
    const location = useLocation();

    document.documentElement.lang = i18n.resolvedLanguage;
    document.title = t('htmlTitle');

    useEffect(() => {
        document.addEventListener('onetrustloaded', () => {
            if (OneTrust.GetDomainData().Language.Culture !== lang) {
                window.OneTrust.changeLanguage();
            }
        });
    }, []);

    useEffect(() => {
        if (lang && i18n.resolvedLanguage !== lang) {
            i18n.changeLanguage(lang);
        }
    }, [location]);

    useEffect(() => {
        if (window.OneTrust && i18n.resolvedLanguage !== OneTrust.GetDomainData().Language.Culture) {
            window.OneTrust.changeLanguage();
        }
    }, [i18n.resolvedLanguage]);

    return <Outlet/>
}